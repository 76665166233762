import posthog from 'posthog-js';
import type { IUserInfo } from '../model/UserInfo';
import type { Proposal } from './Proposal';

class Analytics {
  public onLogin(user: IUserInfo) {
    try {
      posthog.identify(user.deidentifier, {
        role: user.role,
        status: user.status,
        city: user.city,
        state: user.state,
      });
    } catch (e: any) {
      console.error(e);
    }
  }

  public onLogout() {
    try {
      if (process.env.NODE_ENV === 'production') {
        posthog.reset();
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  /**
   * convert a proposal to analytics object
   */
  public mapProposal(proposal: Proposal): Record<string, string | null> {
    return {
      type: proposal.type,
      urgent: String(proposal.isNeededBeforeMedicalDischarge),
      is_decline: String(proposal.isSupportingFunctionalDecline),
      using_insurance: String(proposal.isUsingInsuranceFunds),
      payment_options: proposal.plannedPaymentOptions?.join(',') || null,
      project_stage: proposal.projectStage?.value || null,
      requester_type: proposal.requesterType || null,
      requester_role: proposal.requesterHealthRole || null,
      shared_walls: proposal.hasSharedWalls || null,
    };
  }

  public event(name: string, meta?: Record<string, string | null> | Proposal) {
    try {
      if (meta && 'isUsingInsuranceFunds' in meta) {
        meta = this.mapProposal(meta as Proposal);
      }
      posthog.capture(name, meta);
    } catch (e: any) {
      console.error(e);
    }
  }

  public pageview() {
    try {
      posthog.capture('$pageview');
    } catch (e: any) {
      console.error(e);
    }
  }
}

export const analytics = new Analytics();
